import React, { useState } from "react";
import axios from "axios";
import { Form, Col, Row, Input, FormGroup, CardBody, Button } from "reactstrap";

const InitialState = {
  textNombre: "",
  textApellido: "",
  textTelefono: "",
  textEmail: "",
  textMensaje: "",
}
function Formulario(){
  
  const [state, setState] = useState(InitialState)
  const [loading, setLoading] = useState(false)
  const onChangeText = event => {
    setState({...state, [event.target.name]: event.target.value })
  }
  const onEnviarFormulario = event => {
    event.preventDefault();
    const { textNombre, textApellido, textTelefono, textEmail, textMensaje } = state
    const nombreCompleto = textNombre + " " + textApellido;
    setLoading(true);
    const text = `
			Nombre: ${nombreCompleto} 
			Email: ${textEmail} 
			Mensaje: ${textMensaje}
      Fono: ${textTelefono}
    `
    const info = {
			to: "ventas@parquelosarrayanes.cl",
			replyTo: textEmail,
			subject:'Formulario Contacto',
			text: text
		}
    axios.post("https://us-central1-firemailer.cloudfunctions.net/submitContactoFZ", info)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    setState(InitialState);
  }
  const isInvalid = state.textNombre === "" || state.textApellido === "" || state.textTelefono === "" || state.textEmail === "" || state.textMensaje === "";
  return(
    <Form onSubmit={onEnviarFormulario}>
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup className="label-floating">
                <label className="control-label">Nombre</label>
                <Input
                  name="textNombre"
                  value={state.textNombre}
                  onChange={onChangeText}
                  placeholder=""
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="label-floating">
                <label className="control-label">Apellido</label>
                <Input
                  name="textApellido"
                  value={state.textApellido}
                  onChange={onChangeText}
                  placeholder=""
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="label-floating">
                <label className="control-label">Teléfono</label>
                <Input
                  name="textTelefono"
                  value={state.textTelefono}
                  onChange={onChangeText}
                  placeholder=""
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="label-floating">
            <label className="control-label">Email</label>
            <Input
              name="textEmail"
              value={state.textEmail}
              onChange={onChangeText}
              placeholder=""
              type="email"
            />
          </FormGroup>
          <FormGroup className="label-floating">
            <label className="control-label">Mensaje</label>
            <Input
              id="message"
              name="textMensaje"
              value={state.textMensaje}
              onChange={onChangeText}
              placeholder=""
              type="textarea"
              rows="6"
            />
          </FormGroup>
          <Row>
            <Col md="6">
              <Button type="submit" color="primary" disabled={isInvalid}>
                { loading && (
                    <div className="uil-reload-css reload-small mr-1">
                      <div />
                    </div>
                  )
                }
                Enviar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Form>
  )
}
export default Formulario;
