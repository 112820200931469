import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  minHeight:"550px",
  maxHeight: "620px",
  marginTop:"20px"
};

const center = {
  lat: -41.609043,
  lng: -73.222548,
};

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBX-fT0cZLTKydQN_HFfeCfHKq_Um_4cSQ",
  });
  
  const [map, setMap] = React.useState(null);
  
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      mapTypeId="satellite"
      center={center}
      zoom={16}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}

      <Marker position={{lat:-41.609043, lng:-73.222548}}/>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
