import React from "react";
// reactstrap components
import { CardContacto, ItemNombre, ItemTelefono, ItemEmail, Icon } from "./styles";
// import Chile from "../Icons/Chile";
// import Usa from "../Icons/Usa";
import WhatsApp from "../Icons/WhatsApp";
import Mail from "../Icons/Mail";
// import Phone from "../Icons/Phone";
import Formulario from "./Formulario.js";
import { Card, CardTitle, Container, Row, Col } from "reactstrap";

const contactoContenido = [
  {
    contactoEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <Card className="card-contact no-transition">
              <CardTitle className="text-center" tag="h3">
                Contáctanos
              </CardTitle>
              <Row>
                <Col className="ml-auto" md="5">
                  <h4 className="info-title pl-3 pl-md-0">Información de Ventas</h4>
                  <CardContacto paddingTop="5px">
                    {/* <Chile size="25" /> */}
                    
                    <ItemTelefono>
                      <Icon>
                        <WhatsApp size="25" color="#1EBEA5" />
                      </Icon>
                      <a className="pl-2" href="https://api.whatsapp.com/send?phone=+56982889914">
                        +569 82889914
                      </a>
                    </ItemTelefono>
                    <ItemEmail>
                      <Icon>
                        <Mail size="25" color="#1EBEA5" />
                      </Icon>
                      <a className="pl-2 vertical-align-center" href="mailto:ventas@parquelosarrayanes.cl">
                        ventas@parquelosarrayanes.cl
                      </a>
                    </ItemEmail>  
                  </CardContacto>
                  <hr />
                </Col>
                <Col className="mr-auto" md="5">
                  <Formulario idioma="es" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    contactoEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <Card className="card-contact no-transition">
              <CardTitle className="text-center" tag="h3">
                Contáctanos
              </CardTitle>
              <Row>
                <Col className="ml-auto" md="5">
                  <h4 className="info-title pl-3 pl-md-0">Llámanos</h4>
                  <CardContacto>
                    {/* <Chile size="25" /> */}
                    <ItemNombre>Génesis Gómez</ItemNombre>
                    <ItemTelefono>
                      <WhatsApp size="25" color="#1EBEA5" />
                      <a className="pl-2" href="https://api.whatsapp.com/send?phone=+56942635546">
                        +56 9 4263 5546
                      </a>
                    </ItemTelefono>
                    <ItemEmail>
                      <a className="pl-2" href="mailto:genesis@chenue.cl">
                        genesis@chenue.cl
                      </a>
                    </ItemEmail>  
                  </CardContacto>
                  <hr />
                  <CardContacto>
                    {/* <Chile size="25" /> */}
                    <ItemNombre>Andrés Oyarzún</ItemNombre>
                    <ItemTelefono>
                      <WhatsApp size="25" color="#1EBEA5" />
                      <a
                        className="pl-2"
                        href="https://api.whatsapp.com/send?phone=+56966287537"
                      >
                        +569 66287537
                      </a>
                    </ItemTelefono>
                    <ItemEmail>
                      <Mail size="25" color="#1EBEA5" />
                      <a className="pl-2" href="mailto:andres@chenue.cl">
                        andres@chenue.cl
                      </a>
                    </ItemEmail>
                  </CardContacto>
                  <hr />
                  <CardContacto>
                    {/* <Usa size="25" /> */}
                    <ItemNombre>Jaime Halabí</ItemNombre>
                    <ItemTelefono>
                      <WhatsApp size="25" color="#1EBEA5" />
                      <a
                        className="pl-2"
                        href="https://api.whatsapp.com/send?phone=56982889914"
                      >
                        +56 9 82889914
                      </a>
                    </ItemTelefono>
                    <ItemEmail>
                      <Mail size="25" color="#1EBEA5" />
                      <a className="pl-2" href="mailto:jaime@chenue.cl">
                        jaime@chenue.cl
                      </a>
                    </ItemEmail>
                  </CardContacto>
                </Col>
                <Col className="mr-auto" md="5">
                  <Formulario idioma="es" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    ),
  },
];
function SectionContactUs(props) {
  const renderContacto = () => {
    if (props.idioma === "esp") {
      return contactoContenido[0].contactoEs;
    } else {
      return contactoContenido[1].contactoEn;
    }
  };
  return (
    <>
      <div className="section section-contactus cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/images/sections/contacto.jpg") + ")",
          }}
        >
          {renderContacto()}
        </div>
      </div>
    </>
  );
}

export default SectionContactUs;
