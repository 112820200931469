import React from "react";
import { Container, Row, Col } from "reactstrap";
import Googlemaps from "components/Googlemaps";

const ubicacionTextos = [
  {
    ubicacionEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Ubicación</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Googlemaps/>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal pt-4 pt-md-0">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Lugares Cercanos</h4>
                <ul className="pl-0">
                  <li>Puerto Montt 34 km, 30 min.</li>
                  <li>Calbuco 21 km, 20 min</li>
                  <li>Pargua, 30 km, 25 min</li>
                  <li>Aeropuerto Tepual, 31 km, 28 min</li>
                  <li>Puerto Varas, 46km, 39 min</li>
                </ul>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Atracciones Turisticas</h4>
                
                <ul className="pl-0">
                  <li>Isla Lagartija o Kaikué</li>
                  <li>Isla Puluqui</li>
                  <li>Isla Chaullín o Helvecia</li>
                  <li>Isla Quenu</li>
                  <li>Termas de Puyehue</li>
                  <li>Termas Geométricas</li>
                  <li>Termas Puyuhuapi</li>
                  <li>Termas de Cahuelmo</li>
                  <li>Termas de Cahuelmo, 2 hr</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  },
  {
    ubicacionEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Location</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className="ubicacion-container">
              <iframe
                className="ubicacion-iframe"
                title="ubicacion"
                src="https://www.google.com/maps/d/u/0/embed?mid=1a0JXTwaMMqYFLgbWhYvtkhsgBARQETUT"
              ></iframe>
            </div>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal pt-0 pt-sm-4">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description">
                <h4 className="info-title">To the north</h4>
                <ul className="pl-0">
                  <li>Santa Barbara Beach - 0.9 mi / 1.5 km</li>
                  <li>Cerro El Vilcún (caves with cave paintings): 5 Km</li>
                  <li>Airport: 10 min</li>
                  <li>Millennium Alerces Trail (Pumalín Park): 35 min</li>
                  <li>
                    Caleta Gonzalo (Reñihue Fjord, bimodal route ferry): 50
                    min
                  </li>
                </ul>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description">
                <h4 className="info-title">To the south</h4>
                <ul className="pl-0">
                  <li>Chaitén at 7.5 Kms direct by route 7: 8 min </li>
                  <li>El Amarillo (Pumalín Park)</li>
                  <li>Michimahuida Volcano Trail: 30 min</li>
                  <li>El Amarillo Hot Springs: 45 min</li>
                  <li>Yelcho Lake: 55 min</li>
                  <li>Futaleufú - Futaleufú River: 3 hrs</li>
                  <li>Puyuhuapi (hot springs, Queulat national park): 5 hrs</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
];
function Ubicacion(props) {
  const renderUbicacion = () => {
    if (props.idioma === "esp") {
      return (
        ubicacionTextos[0].ubicacionEs
      )
    } else{
      return (
        ubicacionTextos[1].ubicacionEn
      )
    }
  };
  return (
    <>
      <div className="section section-feature cd-section" id="ubicacion">
        <div className="features-4">{renderUbicacion()}</div>
      </div>
    </>
  );
}

export default Ubicacion;
