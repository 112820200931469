import React from "react";
import { Container, Row, Col } from "reactstrap";

const proyecto = [
  {
    proyectoEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto left pb-5" md="8">
            <h2 className="title">Parque Los Arrayanes</h2>
            <h5 className="description">
              Parque Los Arrayanes es un mágico lugar, ubicado a sólo 20 minutos de Puerto Montt, y a sólo 3
              minutos del bypass con la ruta 5 sur. En este lugar de ensueño podrás disfrutar de su tranquilidad y
              cargarte de energías. Así como también podrás contemplar del Bosque de Arrayanes uno de los 3 más
              antiguos del mundo caracterizado por su abundante flora y fauna.
            </h5>
            <h5 className="description">
              Parque Los Arrayanes se encuentra a 10 minutos de Calbuco, la isla más antigua de las 14 islas de la
              provincia de Llanquihue, la cual comparte vínculos históricos con la vecina provincia de Chiloé. El
              nombre Calbuco proviene del mapudungun kallvü ko/kallfü ko que significa agua azul. Podrás realizar un
              sinnúmero de actividades como cabalgatas, rafting, kayak de río y mar, excursiones a las 13 islas,
              travesías y paseos náuticos son algunas de las alternativas de realizar en esta zona.
            </h5>
            <h5 className="description">
              Este proyecto está enfocado en aquellos que disfrutan de vivir en la naturaleza sin perder la
              conectividad con la ciudad.
            </h5>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    proyectoEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center pb-5" md="8">
            <h2 className="title">
              Fundo Santa Bárbara Condominium/ Gated Community
            </h2>
            <h5 className="description">
              This unique and exclusive project is located in the Northern
              Patagonia of Chile. One of a kind in its natural beauty and
              surrounded by national parks, with direct access to the Austral
              Highway / Route 7. Located 8 km North of Chaitén, this territory
              is delimited on its southern side by an estuary, and where the
              beauty of a small waterfall (common area) stands out. It flows
              into the sea next to caves, small beaches, and a rockery of
              ​​about 500 meters of coastline (Pacific Ocean).
            </h5>
            <h5 className="description">
              The terrane is located in an environmentally protected area,
              inserted in an Evergreen forest. Its rainy climate provides many
              abundant, pure water sources.
            </h5>
            <h5 className="description">
              The project is oriented towards those seeking nature conservation
              and tourism, nature walks, trekking, outdoor activities, nature
              study, amongst others. The sights really leave you at awe, with
              the majesty of its views and untamed nature. All of this added to
              the kindness and caring spirit of its surrounding community of
              locals/ residents. The population of this area is only 1.2
              inhabitants per km2.
            </h5>
            <h5 className="description">
              Construction options are vast, offering numerous building choices
              that can even include a rustic cabin made from native wood and
              fine finishes with local flagstone. The sky is the limit!
            </h5>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    caracteristicasEs: (
      <Container>
        <Row>
          <div className="ml-auto mr-auto">
            <h2 className="title text-center">Características</h2>
          </div>
        </Row>
        <Row>
          <Col className="ml-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-i-lock" />
              </div>
              <h4 className="title">Seguridad</h4>
              <p>
                Acceso controlado - cámaras de seguridad.
              </p>
            </div>
          </Col>
          <Col className="mr-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-water-hand" />
              </div>
              <h4 className="title">Luz y Agua</h4>
              <p>
                Parcelas cuentan con empalmes de electricidad y medidores de agua en cada parcela.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="bottom-line">
          <Col className="ml-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-park" />
              </div>
              <h4 className="title">Área Común</h4>
              <p>
                El área común de 1.5 hectárea contará con un club house, una cancha de pádel y una cancha de fútbol (ambas techadas).
              </p>
            </div>
          </Col>
          <Col className="mr-auto" sm="5">

            <div className="info">
              <div className="icon">
                <i
                  aria-hidden={true}
                  className="nc-icon icon-boat-small-02-2"
                />
              </div>
              <h4 className="title">Acceso a mar</h4>
              <p>
                A 20 minutos del archipiélago de Calbuco donde se encuentran diversas playas, caletas y servicios.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    caracteristicasEn: (
      <></>
    ),
  },
  {
    florayfaunaEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Flora y Fauna</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className="ipad-container">
              <img alt="..." src={require("assets/images/sections/fyf.jpg")} />
            </div>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal pt-md-0">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-tree-01" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Flora</h4>
                <p>
                  Dadas las condiciones climáticas en la región, la vegetación dominante es el bosque templado lluvioso y
                  la selva valdiviana. El bosque templado lluvioso se distribuye hacia la cordillera de los Andes y cuenta
                  con especies como roble, raulí, coigüe, ciprés, lenga y alerce, por nombrar algunas de las más
                  importantes.
                  Destaca la espesa vegetación de la carretera austral que se impone en todo momento, presenta
                  especies nativas como coigüe magallánico, bosques de alerce milenario y bosque de lenga en las
                  inmediaciones del río Palena. En las áreas cordilleranas de esta región reina el coirón, una planta famosa
                  por sus propiedades curativas. Entre tantas islas se destaca La isla Kaikué-Lagartija un santuario de la
                  naturaleza localizada en el golfo de Ancud.
                </p>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-deer" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Fauna</h4>
                <p>
                  Calbuco cuenta con una variedad de mamíferos dentro de los que podemos observar al pudú o venado
                  chileno, pumas, guiñas entre otros.
                  Entre las aves podemos destacar al cóndor, carpintero negro, huelhuel y un sinfín de variedad donde
                  podrás visitar en la isla de los pájaros.
                  Su extenso mar permite avistar diferentes tipos de peces incluyendo la emblemática Ballena azul.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    florayfaunaEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Flora and Fauna</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className="ipad-container">
              <img alt="..." src={require("assets/images/sections/fyf.jpg")} />
            </div>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-tree-01" />
              </div>
              <div className="description">
                <h4 className="info-title">Flora</h4>
                <p>
                  One of the icons of this area is the Larch tree, that can live
                  for many years and grow to more than 50 meters. Other commonly
                  seen tree species are: Lenga, Coigüe, Ñirre, Canelo, Mañío,
                  Luma, Tepa, Ulmo, ciruelillo, amongst others.  The presence of
                  flowers like the Copihues (national flower) is characteristic
                  of its forests.
                </p>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-deer" />
              </div>
              <div className="description">
                <h4 className="info-title">Fauna</h4>
                <p>
                  You can frequently see dolphins, cormorants, penguins, herons
                  and kingfishers in the sea. In the Valdivian rainforests you
                  can go for bird-walks to catch sight of a wide variety of
                  birds such as chucao, hued-hued and rayadito. From the mammal
                  family and not so easy to encounter, are animals such as the
                  pudu, fox, colocolo cat, wink, cougar and an unusual
                  marsupial.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    actividadesEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Actividades</h2>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-walk" />
              </div>
              <div className="description">
                <h4 className="info-title">Senderismo</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-mountain" />
              </div>
              <div className="description">
                <h4 className="info-title">Montañismo</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-horse" />
              </div>
              <div className="description">
                <h4 className="info-title">Cabalgatas</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-rowing" />
              </div>
              <div className="description">
                <h4 className="info-title">Kayak</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-surface-2" />
              </div>
              <div className="description">
                <h4 className="info-title">Rafting</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-fish" />
              </div>
              <div className="description">
                <h4 className="info-title">Pesca Deportiva</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-binocular" />
              </div>
              <div className="description">
                <h4 className="info-title">Avistamiento de Aves</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-aerobics" />
              </div>
              <div className="description">
                <h4 className="info-title">Termas</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-search" />
              </div>
              <div className="description">
                <h4 className="info-title">Geoturismo</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    actividadesEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Activities</h2>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-walk" />
              </div>
              <div className="description">
                <h4 className="info-title">Trekking</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-mountain" />
              </div>
              <div className="description">
                <h4 className="info-title">Climbing</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-horse" />
              </div>
              <div className="description">
                <h4 className="info-title">Horseback riding</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-rowing" />
              </div>
              <div className="description">
                <h4 className="info-title">Kayaking</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-surface-2" />
              </div>
              <div className="description">
                <h4 className="info-title">Rafting</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-fish" />
              </div>
              <div className="description">
                <h4 className="info-title">Fly fishing and fishing</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-binocular" />
              </div>
              <div className="description">
                <h4 className="info-title">Bird Watching</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-aerobics" />
              </div>
              <div className="description">
                <h4 className="info-title">Soaking in hot springs</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-search" />
              </div>
              <div className="description">
                <h4 className="info-title">Geo-tourism</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
];

function Proyecto(props) {
  const renderProyecto = () => {
    if (props.idioma === "esp") {
      return proyecto[0].proyectoEs;
    } else {
      return proyecto[1].proyectoEn;
    }
  };
  const renderCaracteristicas = () => {
    if (props.idioma === "esp") {
      return proyecto[2].caracteristicasEs;
    } else {
      return proyecto[3].caracteristicasEn;
    }
  };
  const renderFloraFauna = () => {
    if (props.idioma === "esp") {
      return proyecto[4].florayfaunaEs;
    } else {
      return proyecto[5].florayfaunaEn;
    }
  };
  // const renderActividades = () => {
  //   if (props.idioma === "esp") {
  //     return proyecto[6].actividadesEs;
  //   } else {
  //     return proyecto[7].actividadesEn;
  //   }
  // };
  return (
    <>
      <div className="section section-feature cd-section">
        <div className="features-1" id="condominio"></div>
        {renderProyecto()}
        <div
          id="caracteristicas"
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/sections/caracteristicas.jpg") +
              ")",
          }}
        >
          {renderCaracteristicas()}
        </div>
        <div id="florayfauna" className="features-4 pb-0">
          {renderFloraFauna()}
        </div>
        {/* <div id="actividades" className="features-1">
          {renderActividades()}
        </div> */}
      </div>
    </>
  );
}
export default Proyecto;
