import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Chile from '../Icons/Chile'
// import Usa from '../Icons/Usa'
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, NavLink, Container } from "reactstrap";
import LogoBlanco from '../../assets/images/logo-arrayanes.png'
import Logo from '../../assets/images/logo-arrayanes-blanco.png'

const textos = {
  esp:{
    "menu":["El proyecto", "masterplan", "galería", "Video", "Equipo", "Ubicación", "Links", "Contacto"],
    "submenu":["El Proyecto", "Características", "Flora y Fauna", "Actividades"]
  },
  en:{
    "menu":["The Project", "Masterplan", "Gallery", "Video", "Team", "Location", "Links", "Contact"],
    "submenu":["The Project", "Features", "Flora and Fauna", "Activities"]}
}

function Menu(props) {

  const [textoMenu, setTextoMenu] = useState(textos.esp)
  // const renderIconIdioma = () => {
  //   if(props.idioma === 'esp'){
  //     return(
  //       <Usa size="30" />
  //     )
  //   }else{
  //     return(
  //       <Chile size="30"/>
  //     )
  //   }
  // } 
  useEffect(() => {
    const updateTexto = () => {
      if(props.idioma === 'esp'){
        setTextoMenu(textos.esp)
      }else{
        setTextoMenu(textos.en)
      }
    }
    updateTexto()
  },[props.idioma])

  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  // const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [imgLogo, setImgLogo] = useState(Logo)
  
  useEffect(() => {
    // initialise
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setImgLogo(LogoBlanco)
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
        setImgLogo(Logo)
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar className={classnames("fixed-top", navbarColor)} expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" className="py-0" to="/#header" tag={Link}>
              <img src={imgLogo} style={{height:'85px'}} alt="img-Fundo-Santa-Barbara"/>
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  {textoMenu.menu[0]}
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/#condominio" tag={Link}>
                    {textoMenu.submenu[0]}
                  </DropdownItem>
                  <DropdownItem to="/#caracteristicas" tag={Link}>
                    {textoMenu.submenu[1]}
                  </DropdownItem>
                  <DropdownItem to="/#florayfauna" tag={Link}>
                    {textoMenu.submenu[2]}
                  </DropdownItem>
                  {/* <DropdownItem to="/#actividades" tag={Link}>
                    {textoMenu.submenu[3]}
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                  <NavLink to="/#masterplan" tag={Link}>
                    {textoMenu.menu[1]}
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/#galeria" tag={Link}>
                    {textoMenu.menu[2]}
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/#video" tag={Link}>
                    {textoMenu.menu[3]}
                  </NavLink>
              </NavItem>
              {/* <NavItem>
                  <NavLink to="/#equipo" tag={Link}>
                    {textoMenu.menu[4]}
                  </NavLink>
              </NavItem> */}
              <NavItem>
                  <NavLink to="/#ubicacion" tag={Link}>
                    {textoMenu.menu[5]} 
                  </NavLink>
              </NavItem>
              {/* <NavItem>
                  <NavLink to="/#links" tag={Link}>
                    {textoMenu.menu[6]}
                  </NavLink>
              </NavItem> */}
              <NavItem>
                <Button
                  className="btn-round outline"
                  color="info"
                  to="/#contact-us"
                  tag={Link}
                >
                  <i className="nc-icon nc-phone" /> {textoMenu.menu[7]}
                </Button>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className="pt-1"
                  onClick={ () => props.cambioIdioma()}
                > 
                  { renderIconIdioma()}
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Menu;
